/**
 * 账号校验
 * @param {}} rule
 * @param {*} value
 * @param {*} callback
 */
export const validateAccount = (rule, value, callback) => {
  callback()
}
/**
 * 密码校验
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
export const validatePassword = (rule, value, callback) => {
  callback()
}
