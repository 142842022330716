<template>
  <div>
    <div class="login-content fixed_center">
      <!-- <a href="http://www.jfjtsc.com" target="_blank">
      </a> -->
      <div class="login-img"></div>

      <div class="login-form">
        <div class="title">{{ title }}</div>
        <p class="first_title">账号登录</p>
        <el-form
          :model="formData"
          :rules="rules"
          ref="ruleForm"
          label-width="0px"
          class="login-container"
        >
          <div class="form-item">
            <span class="label">用户名</span>
            <div class="input-box">
              <i class="iconfont iconyonghu"></i>
              <el-form-item prop="account">
                <el-input
                  v-model="formData.account"
                  autocomplete="off"
                  ref="accountRef"
                  maxlength="50"
                  placeholder="请输入用户名"
                  @focus="handleAccountFocus()"
                  @blur="handleAccountBlur()"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="form-item">
            <span class="label">密码</span>
            <div class="input-box">
              <i class="iconfont iconmima"></i>
              <el-form-item prop="password">
                <el-input
                  type="password"
                  ref="passwordRef"
                  maxlength="50"
                  v-model="formData.password"
                  autocomplete="off"
                  placeholder="请输入密码"
                  @focus="handlePasswordFocus()"
                  @blur="handlePasswordBlur()"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <!-- <div class="remember">
          <el-checkbox></el-checkbox>
          记住密码
        </div> -->
        </el-form>
        <base-button
          label="登录"
          @click="handleLogin"
          :loading="loginLoading"
          class="login_btn"
        ></base-button>
      </div>
    </div>
    <footer>
      Copyright ©2022 泉州交发集团 版权所有
      <br />

      <a href="https://beian.miit.gov.cn" title="网站备案" target="_blank"
        >备案号：闽ICP备2022019388号</a
      >
    </footer>
  </div>
</template>

<script>
import { login } from '@/api/sys/user'
// import baseForm from '@/components/common/base-form//base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { mapActions } from 'vuex'
import { validateAccount, validatePassword } from './validate'
import Storage from '@/utils/storage'
export default {
  components: { BaseButton },
  mounted () {
    this.$store.dispatch('user/clearUserInfo')
  },
  data () {
    return {
      title: '交发投资集团供应链服务平台',
      loginLoading: false,
      formData: {
        account: '',
        password: ''
      },
      rules: {
        account: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { validator: validateAccount, trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { validator: validatePassword, trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  created () {
    Storage.setSession('authStatus', true)
  },
  methods: {
    ...mapActions({
      setUserInfo: 'user/initUserInfo'
    }),

    handleKeyDownEvent (e) {
      if (e.keyCode === 13) {
        this.handleAccountEnter()
      }
    },
    handleKeyDownEventPassWord (e) {
      if (e.keyCode === 13) {
        this.handlePasswordEnter()
      }
    },

    handleAccountBlur () {
      window.removeEventListener('keydown', this.handleKeyDownEvent, false)
    },
    handleAccountFocus () {
      window.addEventListener('keydown', this.handleKeyDownEvent, false)
    },

    handlePasswordBlur () {
      window.removeEventListener(
        'keydown',
        this.handleKeyDownEventPassWord,
        false
      )
    },
    handlePasswordFocus () {
      window.addEventListener(
        'keydown',
        this.handleKeyDownEventPassWord,
        false
      )
    },

    handlePasswordEnter () {
      // 有密码 没有账号
      if (!this.formData.account && this.formData.password) {
        this.$refs.accountRef.focus()
      } else if (this.formData.account && this.formData.password) {
        this.handleLogin()
      } else {
        // 只展示错误信息
        this.$refs.ruleForm.validateField('password')
      }
    },
    handleAccountEnter () {
      // 只有用户名  没有密码
      if (this.formData.account && !this.formData.password) {
        this.$refs.passwordRef.focus()
      } else if (this.formData.account && this.formData.password) {
        this.handleLogin()
      } else {
        // 只展示错误信息
        this.$refs.ruleForm.validateField('account')
      }
    },

    handleLogin: async function () {
      if (this.loginLoading) {
        return
      }
      try {
        this.loginLoading = true
        await this.validate()
        const { data } = await login(this.formData)
        await this.setUserInfo(data)
        this.success('登录成功')
        this.$router.replace({
          path: '/'
        })
      } catch (error) {
        if (error === false) {
          this.warning('请完善表单')
        } else {
          this.error(error.message || error)
        }
      } finally {
        this.loginLoading = false
      }
    },
    /**
     * 表单校验
     */
    validate: function () {
      return this.$refs.ruleForm.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 30px;
}
.login-content {
  width: 1280px;
  height: 720px;
  // box-shadow: 0px 10px 20px 0px rgba(239, 128, 44, 0.3);
  box-shadow: 0px 20px 10px rgba(40, 98, 231, 0.15);
  display: flex;
  .login-img {
    width: 600px;
    height: 100%;
    background-image: url("../../assets/login/login-form_bg-old.png");
    background-size: cover;
  }
}
.login-form {
  width: 680px;
  height: 100%;
  background: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  .title {
    margin-top: 107px;
    font-size: 36px;
    line-height: 20px;
    font-weight: bold;
    color: #333333;
  }
  .first_title {
    font-size: 28px;
    line-height: 42px;
    font-weight: 400;
    position: relative;
    margin: 55px 0 0 0;
  }

  .login-container {
    width: 400px;
    .form-item {
      margin-top: 20px;
      &:nth-child(2) {
        margin-top: 40px;
      }
      .label {
        display: block;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: #38373a;
      }
      .input-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 11.375px;
        padding: 4px 10px;
        border: 1px solid #dddddd;
        .iconfont {
          width: 22px;
          font-size: 22px;
          color: #6d7780;
        }
        .el-form-item {
          flex: 1;
          margin-bottom: 0px;
          /deep/ input {
            border: none;
            font-size: 14px;
            line-height: 14px;
          }
          /deep/ .el-input__inner {
            padding: 6px;
          }
          /deep/ .el-form-item__error {
            top: calc(100% + 8px);
            left: 6px;
          }
        }
      }
    }
  }

  .login_btn {
    margin-top: 105px;
    font-size: 18px;
    width: 400px;
    height: 50px;
    background: #2862e7;

    &:active {
      box-shadow: none;
    }
  }
}
</style>
